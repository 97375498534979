<script setup lang="ts">

</script>

<template>
  <div h-screen>
    <iframe src="https://cd217.cc/index1.html?channelCode=216" h-full w-full flex-1 />
  <TheFooter fixed bottom-0 h-50 w-full />
</div>
</template>
